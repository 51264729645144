import React from 'react';
import { PRODUCT_COOKIES } from '../constants';
import EssentialOnePage from './product/essential-one';

const EssentialOneFreeTrial: React.FC = () => {
  return (
    <EssentialOnePage
      noindex
      isLandingPage={true}
      isFreeTrial={true}
      partnership={PRODUCT_COOKIES.ESSENTIAL_ONE.value.AFFILIATES}
    />
  );
};

export default EssentialOneFreeTrial;
